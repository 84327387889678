import React, { useRef, useState, useEffect } from 'react';

export function FrameWrapper({ html }) {
    const ref = useRef();
    const [height, setHeight] = useState('0px');

    const onLoad = () => {
        setHeight(`${ref.current.contentWindow.document.body.scrollHeight + 10}px`);
    };
    useEffect(() => {
        onLoad();
    }, []);

    return (
        <iframe
            ref={ref}
            onLoad={onLoad}
            className="w-full"
            id="emailtimelineiframe"
            srcDoc={html}
            height={height}
            scrolling="no"
            frameBorder="0"
        />
    );
}