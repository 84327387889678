import React, { useState, useEffect } from 'react';
import ical from 'ical';
import { format } from 'date-fns';

function EventViewer({ icsString }) {
  const [eventDetails, setEventDetails] = useState(null);

  useEffect(() => {
    if (!icsString) {
      return;
    }
    try {
      const parsedData = ical.parseICS(icsString);

      const events = Object.values(parsedData).filter((item) => item.type === 'VEVENT');

      if (events.length > 0) {
        const event = events[0];

        setEventDetails({
          summary: event?.summary || 'No title',
          description: event?.description,
          start: new Date(event?.start),
          end: new Date(event?.end),
          location: event?.location || 'N/A',
          organizer: event?.organizer
            ? {
                name: event?.organizer?.params?.CN || 'Unknown Organizer',
                email: event?.organizer?.val?.replace('mailto:', '')
              }
            : null,
          attendees: (event?.attendee || []).map((attendee) => ({
            name: attendee?.params?.CN || 'Unknown',
            email: attendee?.val
          }))
        });
      }
    } catch (error) {
      console.error('Error parsing ICS:', error);
    }
  }, [icsString]);

  if (!icsString) {
    return <p className="text-gray-500 text-lg">No ICS details provided.</p>;
  }

  if (!eventDetails) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-gray-500 text-lg">Loading ics details...</p>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center">
      <div className="bg-gray-100 shadow-md rounded-lg p-6 w-120">
        <h2 className="text-xl font-bold text-gray-800 mb-4">{eventDetails.summary}</h2>
        <p className="text-gray-600 mb-2">
          <strong>Description:</strong> {eventDetails.description || 'N/A'}
        </p>
        <p className="text-gray-600 mb-2">
          <strong>Start:</strong> {format(eventDetails.start, 'PPpp')}
        </p>
        <p className="text-gray-600 mb-2">
          <strong>End:</strong> {format(eventDetails.end, 'PPpp')}
        </p>
        <p className="text-gray-600 mb-2">
          <strong>Location:</strong>{' '}
          <a
            href={eventDetails.location}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            {eventDetails.location}
          </a>
        </p>
        <p className="text-gray-600 mb-2">
          <strong>Organizer:</strong>{' '}
          {eventDetails.organizer ? (
            <>
              {eventDetails.organizer.name} (
              <a
                href={`mailto:${eventDetails.organizer.email}`}
                className="text-blue-600 underline"
              >
                {eventDetails.organizer.email}
              </a>
              )
            </>
          ) : (
            'N/A'
          )}
        </p>
        <div className="mt-4">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Attendees</h3>
          <ul className="list-disc list-inside text-gray-600">
            {eventDetails.attendees.map((attendee, index) => (
              <li key={index}>
                {attendee.name} ({' '}
                <a href={`${attendee.email}`} className="text-blue-600 underline">
                  {attendee.email.replace('mailto:', '')}
                </a>
                )
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default EventViewer;
