import React, { useState } from 'react';
import './index.css';

const ImgUpload = ({ onChange, src }) => (
  <label htmlFor="photo-upload" className="custom-file-upload fas">
    <div className="img-wrap img-upload">
      <img alt="upload-preview" src={src} />
    </div>
    <input className="chat-img-upload" id="photo-upload" type="file" onChange={onChange} />
  </label>
);

const ImageUploader = ({
  previewUrl = 'https://cloudimages.myyogateacher.com/dgerdfai4/image/upload/v1587531490/ic_default_profile.png',
  onImageUpload
}) => {
  const [file, setFile] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState(previewUrl);

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
    onImageUpload(file);
  };

  return (
    <div>
      <ImgUpload onChange={photoUpload} src={imagePreviewUrl} />
      {/* Add additional elements like name, status, and submit button here if needed */}
    </div>
  );
};

export default ImageUploader;
