import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { get_api_url } from '../../../../utils/urls';
import { api_with_method } from '../../../../redux/api_funcs';

function AllowedParticipants(props) {
    const history = useHistory();
    const [participants] = useState(props.allowedParticipantsList);

    const removeParticipants = (studentUUID, teacherUUID) => {
        const path = `/v2/zoom_services/participants`;
        const url = get_api_url(path, false, true);
        const payload = {
            student_uuid: studentUUID
        };

        api_with_method('delete', url, payload, true, 'none')
            .then(() => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <>
            {participants.map((participant, index) => {
                const {
                    teacher_name,
                    student_name,
                    student_email,
                    teacher_email,
                    student_uuid,
                    teacher_uuid
                } = participant;
                return (
                    <div className="ct-row flex" key={index}>
                        <div
                            className="ct-col ct-sm-font"
                            onClick={() => history.push(`/studentprofile?id=${student_uuid}`)}
                        >
                            <p className="m-0">{student_name}</p>
                            <p className="m-0 text-mytLightGray">{student_email}</p>
                        </div>
                        <div className="ct-col ct-sm-font">
                            <button onClick={() => removeParticipants(student_uuid, teacher_uuid)}>Remove</button>
                        </div>
                    </div>
                );
            })}
        </>
    );
}

export default AllowedParticipants;
