import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Papa from 'papaparse';
import CommonHeader from '../../util_components/common_header';
import {
  payment_body,
  late_join_block,
  no_show_block,
  cancellation_block,
  replacement_block,
  day_wise_block,
  day_wise_summary_block,
  conversion_block,
  early_exit_penalty_block,
  late_audio_video_turn_on,
  cancellation_block_within_one_hour,
  replacement_block_within_one_hour,
  reschedules_block,
  montly_payment_block
} from '../../../constants/email_template';
import * as actions from '../../../redux/action_creators';
import CustomInput from '../../util_components/custom_input';
import CustomModal from '../../util_components/custom_modal';
import { post_api } from '../../../redux/api_funcs';
import Button from '../../util_components/button';
import { invalidations } from '../../../constants/index';
import { get_api_url } from '../../../utils/urls';

const titles = {
  late_join: 'Late Joins',
  no_show: 'No Shows',
  cancellation: 'Cancellation with 24 hrs',
  replacement: 'Replacement within 24hrs',
  day_wise: 'Day Wise Breakdown',
  conversion_bonus: 'Conversion Bonus',
  early_exit_penalty: 'Early Exit penalty',
  late_audio_video_turn_on: 'Late Audio/Video Turn On',
  cancellation_within_1_hour: 'Cancellation within 1 hour',
  replacement_within_1_hour: 'Replacement within 1 hour',
  reschedules: 'Reschedules'
};
class TeacherPayments extends Component {
  state = {
    loading: false,
    teacher_data: [],
    final_data: [],
    all_teacher_data: [],
    subject: '',
    email_body: '',
    open_confirm: false
  };

  parse_results_conversion = (data) => {
    const all_teacher_ids = data.map((t) => t.teacher_id);
    const teacher_ids = [...new Set(all_teacher_ids)];
    const final_data = [];
    for (const teacher_id of teacher_ids) {
      if (!teacher_id) {
        continue;
      }
      const teacher_data = data.filter((t) => t.teacher_id === teacher_id);
      const final_teacher_data = {
        teacher_id: teacher_id.replace(',', ''),
        students: [],
        name: teacher_data[0].teacher_name
      };
      const student_ids = [...new Set(teacher_data.map((t) => t.student_id))];
      let total_bonus = 0;
      for (const student_id of student_ids) {
        const student_data = data.filter((t) => t.student_id === student_id);
        const session_data = student_data[student_data.length - 1];
        const student_obj = {
          student_name: session_data.student_name,
          student_id,
          session_time: session_data.start_time,
          total_sessions: student_data.length,
          bonus: session_data.Bonus
        };
        final_teacher_data.students.push(student_obj);
        total_bonus += session_data.Bonus ? parseInt(session_data.Bonus.replace(',', '')) : 0;
      }
      final_teacher_data.total_bonus = total_bonus;
      final_data.push(final_teacher_data);
    }
    const all_teacher_data = [...this.state.all_teacher_data];
    for (const teacher_data of final_data) {
      const index = all_teacher_data.findIndex((a) => a.id === teacher_data.teacher_id);
      if (index !== -1) {
        const teach_data = all_teacher_data[index];
        teach_data.conversion_content = this.parse_conversion_data(teacher_data);
      } else {
        const teacher_obj = {
          id: teacher_data.teacher_id,
          teacher_name: teacher_data.name,
          conversion_content: this.parse_conversion_data(teacher_data)
        };
        all_teacher_data.push(teacher_obj);
      }
    }
    this.setState({ all_teacher_data: [...all_teacher_data], loading: false });
  };

  parse_results_day_wise = (data) => {
    const all_teacher_ids = data.map((t) => t.teacher_id);
    const teacher_ids = [...new Set(all_teacher_ids)];
    const final_data = [];
    for (const teacher_id of teacher_ids) {
      if (!teacher_id) {
        continue;
      }
      const teacher_data = data.filter((t) => t.teacher_id === teacher_id);
      const final_teacher_data = {
        teacher_id: teacher_id.replace(',', ''),
        data: teacher_data,
        name: teacher_data[0].teacher_name
      };
      const total_penalty = teacher_data.reduce((total, t) => {
        const penalty_val = t.penalty ? parseInt(t.penalty.replace(',', '')) : 0;
        return total + penalty_val;
      }, 0);
      const session_data = teacher_data.map((t) => (t.pass_days ? parseInt(t.pass_days) : 0));
      final_teacher_data.opening_balance = Math.max(...session_data);
      final_teacher_data.availed_balance = teacher_data.reduce((total, t) => {
        const bal = t.free_pass_days_applicable?.toLowerCase() === 'yes' ? 1 : 0;
        return total + bal;
      }, 0);
      final_teacher_data.waived_balance = teacher_data.reduce((total, t) => {
        const bal =
          t.free_pass_days_applicable?.toLowerCase() === 'yes' && !!t.penalty
            ? parseInt(t.penalty.replace(',', ''))
            : 0;
        return total + bal;
      }, 0);
      const date_val = moment(teacher_data[0].class_date);
      final_teacher_data.month_val = date_val.format('MMMM');
      final_teacher_data.year_val = date_val.format('YYYY');
      final_teacher_data.end_of_month = date_val.endOf('month').format('MMMM DD, YYYY');
      final_teacher_data.total_penalty = total_penalty;
      final_data.push(final_teacher_data);
    }
    const all_teacher_data = [...this.state.all_teacher_data];
    for (const teacher_data of final_data) {
      const index = all_teacher_data.findIndex((a) => a.id === teacher_data.teacher_id);
      if (index !== -1) {
        const teach_data = all_teacher_data[index];
        teach_data.day_wise_content = this.parse_pay_wise_data(teacher_data);
      } else {
        const teacher_obj = {
          id: teacher_data.teacher_id,
          teacher_name: teacher_data.name,
          day_wise_content: this.parse_pay_wise_data(teacher_data)
        };
        all_teacher_data.push(teacher_obj);
      }
    }
    this.setState({ all_teacher_data: [...all_teacher_data], loading: false });
  };

  parse_conversion_data = (data) => {
    const final_val = [];
    for (const student of data.students) {
      const col_value = `<tr><td>${student.student_name}</td>
                    <td>${student.session_time}</td>
                    <td>${student.total_sessions}</td>
                    <td>${student.bonus}</td></tr>`;
      final_val.push(col_value);
    }
    final_val.push(`<tr><td colspan="3">Total</td><td>${data?.total_bonus}</td></tr>`);
    const raw_html = `${final_val.join('').trim()}`;
    return raw_html;
  };

  parse_pay_wise_data = (teacher_data) => {
    const final_val = [];
    for (const data of teacher_data.data) {
      const penalty = data.penalty ? data.penalty : 0;
      const text_val = `<tr><td>${data.class_date}</td>
                                    <td>${data.free_pass_days_applicable}</td>
                                    <td>-${penalty}</td></tr>`;
      final_val.push(text_val);
    }
    final_val.push(
      `<tr><td colspan="2">Total</td><td>${-1 * teacher_data?.total_penalty}</td></tr>`
    );
    let raw_html = day_wise_block
      .toString()
      .replace('[DAY-WISE-BLOCK]', `${final_val.join('').trim()}`);
    let summary_html = day_wise_summary_block
      .toString()
      .replace('[OPENING-BALANCE]', teacher_data.opening_balance);
    summary_html = summary_html.replace('[PASS-AVAILED]', teacher_data.availed_balance);
    summary_html = summary_html.replace('[TOTAL-PENALTY]', -1 * teacher_data.total_penalty);
    summary_html = summary_html.replace('[PENALTY-WAIVED]', teacher_data.waived_balance);
    summary_html = summary_html.replace(
      '[FINAL-PENALTY]',
      teacher_data.total_penalty - teacher_data.waived_balance
    );
    summary_html = summary_html.replace(
      '[CLOSING-BALANCE]',
      teacher_data.opening_balance - teacher_data.availed_balance
    );

    summary_html = summary_html.replace(/\[DAY-WISE-MONTH\]/g, teacher_data.month_val);
    summary_html = summary_html.replace(/\[DAY-WISE-YEAR\]/g, teacher_data.year_val);
    summary_html = summary_html.replace(/\[END-OF-MONTH\]/g, teacher_data.end_of_month);
    raw_html += summary_html;
    return raw_html;
  };

  parse_results_replacement = (data) => {
    const all_teacher_ids = data.map((t) => t.teacher_id);
    const teacher_ids = [...new Set(all_teacher_ids)];
    const final_data = [];
    for (const teacher_id of teacher_ids) {
      if (!teacher_id) {
        continue;
      }
      const teacher_data = data.filter((t) => t.teacher_id === teacher_id);
      const final_teacher_data = {
        teacher_id: teacher_id.replace(',', ''),
        session_type: [],
        name: teacher_data[0].teacher_name
      };
      let row_val = 0;
      const session_types = [...new Set(teacher_data.map((t) => t.session_type))];
      for (const session_type of session_types) {
        const session_type_data = teacher_data.filter((t) => t.session_type === session_type);
        const session_type_obj = {
          session_type,
          session_type_data: []
        };
        let row_val_session = 0;
        for (const single_session of session_type_data) {
          session_type_obj.session_type_data.push(single_session);
          row_val += 1;
          row_val_session += 1;
        }
        session_type_obj.row_val = row_val_session;
        final_teacher_data.session_type.push(session_type_obj);
      }
      final_teacher_data.row_val = row_val;
      const total_penalty = teacher_data.reduce((total, t) => {
        const penalty_val = t.penalty ? parseInt(t.penalty.replace(',', '')) : 0;
        return total + penalty_val;
      }, 0);
      final_teacher_data.total_penalty = total_penalty;
      final_data.push(final_teacher_data);
    }
    const all_teacher_data = [...this.state.all_teacher_data];
    for (const teacher_data of final_data) {
      const index = all_teacher_data.findIndex((a) => a.id === teacher_data.teacher_id);
      if (index !== -1) {
        const teach_data = all_teacher_data[index];
        teach_data.replacement_content = this.parse_replacement_data(teacher_data);
      } else {
        const teacher_obj = {
          id: teacher_data.teacher_id,
          teacher_name: teacher_data.name,
          replacement_content: this.parse_replacement_data(teacher_data)
        };
        all_teacher_data.push(teacher_obj);
      }
    }
    this.setState({ all_teacher_data: [...all_teacher_data], loading: false });
  };

  parse_replacement_data = (teacher_data) => {
    const final_val = [];
    for (let i = 0; i < teacher_data.row_val; i++) {
      final_val.push(`<tr>[col1]</tr>`);
    }
    let col1Index = 0;
    let col2Index = 0;
    for (const session_type of teacher_data.session_type) {
      for (let i = 0; i < session_type.row_val; i++) {
        let row = final_val[col1Index];
        if (i == 0) {
          row = row.replace(
            '[col1]',
            `<td rowspan=${session_type.row_val}>${session_type.session_type}</td>[col2]`
          );
        } else row = row.replace('[col1]', `[col2]`);
        final_val[col1Index] = row;
        col1Index++;
      }
      for (const data of session_type.session_type_data) {
        const penalty = data.penalty ? data.penalty : 0;
        const col_2_value = `<td>${data.duration}</td>
                                    <td>${data.class_date}</td>
                                    <td>${data.class_time}</td>
                                    <td>${data.class_name}</td>
                                    <td>${data.teacher_rate}</td>
                                    <td>${data.replacement_request_time}</td>
                                    <td>${data.replacement_session_status}</td>
                                    <td>${penalty}</td>`;
        let row = final_val[col2Index];
        row = row.replace('[col2]', col_2_value);
        final_val[col2Index] = row;
        col2Index++;
      }
    }
    final_val.push(`<tr><td colspan="8">Total</td><td>${teacher_data?.total_penalty}</td></tr>`);
    const raw_html = `${final_val.join('').trim()}`;
    return raw_html;
  };

  parse_results_cancellation = (data) => {
    const all_teacher_ids = data.map((t) => t.teacher_id);
    const teacher_ids = [...new Set(all_teacher_ids)];
    const final_data = [];
    for (const teacher_id of teacher_ids) {
      if (!teacher_id) {
        continue;
      }
      const teacher_data = data.filter((t) => t.teacher_id === teacher_id);
      const final_teacher_data = {
        teacher_id: teacher_id.replace(',', ''),
        session_type: [],
        name: teacher_data[0].teacher_name
      };
      let row_val = 0;
      const session_types = [...new Set(teacher_data.map((t) => t.session_type))];
      for (const session_type of session_types) {
        const session_type_data = teacher_data.filter((t) => t.session_type === session_type);
        const session_type_obj = {
          session_type,
          session_type_data: []
        };
        let row_val_session = 0;
        for (const single_session of session_type_data) {
          session_type_obj.session_type_data.push(single_session);
          row_val += 1;
          row_val_session += 1;
        }
        session_type_obj.row_val = row_val_session;
        final_teacher_data.session_type.push(session_type_obj);
      }
      final_teacher_data.row_val = row_val;
      const total_penalty = teacher_data.reduce((total, t) => {
        const penalty_val = t.penalty ? parseInt(t.penalty.replace(',', '')) : 0;
        return total + penalty_val;
      }, 0);
      final_teacher_data.total_penalty = total_penalty;
      final_data.push(final_teacher_data);
    }
    const all_teacher_data = [...this.state.all_teacher_data];
    for (const teacher_data of final_data) {
      const index = all_teacher_data.findIndex((a) => a.id === teacher_data.teacher_id);
      if (index !== -1) {
        const teach_data = all_teacher_data[index];
        teach_data.cancellation_content = this.parse_cancellation_data(teacher_data);
      } else {
        const teacher_obj = {
          id: teacher_data.teacher_id,
          teacher_name: teacher_data.name,
          cancellation_content: this.parse_cancellation_data(teacher_data)
        };
        all_teacher_data.push(teacher_obj);
      }
    }
    this.setState({ all_teacher_data: [...all_teacher_data], loading: false });
  };

  parse_cancellation_data = (teacher_data) => {
    const final_val = [];
    for (let i = 0; i < teacher_data.row_val; i++) {
      final_val.push(`<tr>[col1]</tr>`);
    }
    let col1Index = 0;
    let col2Index = 0;
    for (const session_type of teacher_data.session_type) {
      for (let i = 0; i < session_type.row_val; i++) {
        let row = final_val[col1Index];
        if (i == 0) {
          row = row.replace(
            '[col1]',
            `<td rowspan=${session_type.row_val}>${session_type.session_type}</td>[col2]`
          );
        } else row = row.replace('[col1]', `[col2]`);
        final_val[col1Index] = row;
        col1Index++;
      }
      for (const data of session_type.session_type_data) {
        const penalty = data.penalty ? data.penalty : 0;
        const col_2_value = `<td>${data.duration}</td>
                                    <td>${data.class_date}</td>
                                    <td>${data.class_time}</td>
                                    <td>${data.class_name}</td>
                                    <td>${data.teacher_rate}</td>
                                    <td>${data.cancellation_time}</td>
                                    <td>${data.session_booking_time}</td>
                                    <td>${penalty}</td>`;
        let row = final_val[col2Index];
        row = row.replace('[col2]', col_2_value);
        final_val[col2Index] = row;
        col2Index++;
      }
    }
    final_val.push(`<tr><td colspan="8">Total</td><td>${teacher_data?.total_penalty}</td></tr>`);
    const raw_html = `${final_val.join('').trim()}`;
    return raw_html;
  };

  parse_results_no_show = (data) => {
    const all_teacher_ids = data.map((t) => t.teacher_id);
    const teacher_ids = [...new Set(all_teacher_ids)];
    const final_data = [];
    for (const teacher_id of teacher_ids) {
      if (!teacher_id) {
        continue;
      }
      const teacher_data = data.filter((t) => t.teacher_id === teacher_id);
      const final_teacher_data = {
        teacher_id: teacher_id.replace(',', ''),
        session_type: [],
        name: teacher_data[0].teacher_name
      };
      let row_val = 0;
      const session_types = [...new Set(teacher_data.map((t) => t.session_type))];
      for (const session_type of session_types) {
        const session_type_data = teacher_data.filter((t) => t.session_type === session_type);
        const session_type_obj = {
          session_type,
          session_type_data: []
        };
        let row_val_session = 0;
        for (const single_session of session_type_data) {
          session_type_obj.session_type_data.push(single_session);
          row_val += 1;
          row_val_session += 1;
        }
        session_type_obj.row_val = row_val_session;
        final_teacher_data.session_type.push(session_type_obj);
      }
      final_teacher_data.row_val = row_val;
      const total_penalty = teacher_data.reduce((total, t) => {
        const penalty_val = t.penalty ? parseInt(t.penalty.replace(',', '')) : 0;
        return total + penalty_val;
      }, 0);
      final_teacher_data.total_penalty = total_penalty;
      final_data.push(final_teacher_data);
    }
    const all_teacher_data = [...this.state.all_teacher_data];
    for (const teacher_data of final_data) {
      const index = all_teacher_data.findIndex((a) => a.id === teacher_data.teacher_id);
      if (index !== -1) {
        const teach_data = all_teacher_data[index];
        teach_data.no_show_content = this.parse_no_show_data(teacher_data);
      } else {
        const teacher_obj = {
          id: teacher_data.teacher_id,
          teacher_name: teacher_data.name,
          no_show_content: this.parse_no_show_data(teacher_data)
        };
        all_teacher_data.push(teacher_obj);
      }
    }
    this.setState({ all_teacher_data: [...all_teacher_data], loading: false });
  };

  parse_no_show_data = (teacher_data) => {
    const final_val = [];
    for (let i = 0; i < teacher_data.row_val; i++) {
      final_val.push(`<tr>[col1]</tr>`);
    }
    let col1Index = 0;
    let col2Index = 0;
    for (const session_type of teacher_data.session_type) {
      for (let i = 0; i < session_type.row_val; i++) {
        let row = final_val[col1Index];
        if (i == 0) {
          row = row.replace(
            '[col1]',
            `<td rowspan=${session_type.row_val}>${session_type.session_type}</td>[col2]`
          );
        } else row = row.replace('[col1]', `[col2]`);
        final_val[col1Index] = row;
        col1Index++;
      }
      for (const data of session_type.session_type_data) {
        const penalty = data.penalty ? data.penalty : 0;
        const col_2_value = `<td>${data.duration}</td>
                                    <td>${data.class_date}</td>
                                    <td>${data.class_time}</td>
                                    <td>${data.class_name}</td>
                                    <td>${data.teacher_rate}</td>
                                    <td>${penalty}</td>`;
        let row = final_val[col2Index];
        row = row.replace('[col2]', col_2_value);
        final_val[col2Index] = row;
        col2Index++;
      }
    }
    final_val.push(`<tr><td colspan="6">Total</td><td>${teacher_data?.total_penalty}</td></tr>`);
    const raw_html = `${final_val.join('').trim()}`;
    return raw_html;
  };

  // parse_results_new = data => this.setState({loading: true}, () => {})
  parse_results_late_join = (data) =>
    this.setState({ loading: true }, () => {
      const all_teacher_ids = data.map((t) => t.teacher_id);
      const teacher_ids = [...new Set(all_teacher_ids)];
      const final_data = [];
      for (const teacher_id of teacher_ids) {
        if (!teacher_id) {
          continue;
        }
        const teacher_data = data.filter((t) => t.teacher_id === teacher_id);
        const final_teacher_data = {
          teacher_id: teacher_id.replace(',', ''),
          session_type: [],
          name: teacher_data[0].teacher_name
        };
        let row_val = 0;
        const session_types = [...new Set(teacher_data.map((t) => t.session_type))];
        for (const session_type of session_types) {
          const session_type_data = teacher_data.filter((t) => t.session_type === session_type);
          const session_type_obj = {
            session_type,
            session_type_data: []
          };
          let row_val_session = 0;
          for (const single_session of session_type_data) {
            const obj = {
              class_date: single_session.class_date,
              class_time: single_session.class_time,
              first_student_join_time: single_session.first_student_join_time,
              penalty: single_session.penalty,
              session_duration: single_session.session_duration,
              session_join_time: single_session.session_join_time,
              teacher_rate: single_session.teacher_rate,
              class_name: single_session.class_name
            };
            session_type_obj.session_type_data.push(obj);
            row_val += 1;
            row_val_session += 1;
          }
          session_type_obj.row_val = row_val_session;
          final_teacher_data.session_type.push(session_type_obj);
        }
        final_teacher_data.row_val = row_val;
        const total_penalty = teacher_data.reduce((total, t) => {
          const penalty_val = t.penalty ? parseInt(t.penalty) : 0;
          return total + penalty_val;
        }, 0);
        final_teacher_data.total_penalty = total_penalty;
        final_data.push(final_teacher_data);
      }
      // let all_teacher_data = [];
      const all_teacher_data = [...this.state.all_teacher_data];
      for (const teacher_data of final_data) {
        const index = all_teacher_data.findIndex((a) => a.id === teacher_data.teacher_id);
        if (index !== -1) {
          const teach_data = all_teacher_data[index];
          teach_data.late_join_content = this.parse_late_join_data(teacher_data);
        } else {
          const teacher_obj = {
            id: teacher_data.teacher_id,
            teacher_name: teacher_data.name,
            late_join_content: this.parse_late_join_data(teacher_data)
          };
          all_teacher_data.push(teacher_obj);
        }
      }
      this.setState({ all_teacher_data: [...all_teacher_data], loading: false });
    });

  parse_late_join_data = (teacher_data) => {
    const final_val = [];
    for (let i = 0; i < teacher_data.row_val; i++) {
      final_val.push(`<tr>[col1]</tr>`);
    }
    let col1Index = 0;
    let col2Index = 0;
    for (const session_type of teacher_data.session_type) {
      for (let i = 0; i < session_type.row_val; i++) {
        let row = final_val[col1Index];
        if (i == 0) {
          row = row.replace(
            '[col1]',
            `<td rowspan=${session_type.row_val}>${session_type.session_type}</td>[col2]`
          );
        } else row = row.replace('[col1]', `[col2]`);
        final_val[col1Index] = row;
        col1Index++;
      }
      for (const data of session_type.session_type_data) {
        const penalty = data.penalty ? data.penalty : 0;
        const col_2_value = `<td>${data.session_duration}</td>
                                    <td>${data.teacher_rate}</td>
                                    <td>${data.class_date}</td>
                                    <td>${data.class_time}</td>
                                    <td>${data.class_name}</td>
                                    <td>${data.first_student_join_time}</td>
                                    <td>${data.session_join_time}</td>
                                    <td>${penalty}</td>`;
        let row = final_val[col2Index];
        row = row.replace('[col2]', col_2_value);
        final_val[col2Index] = row;
        col2Index++;
      }
    }
    final_val.push(`<tr><td colspan="8">Total</td><td>${teacher_data?.total_penalty}</td></tr>`);
    const raw_html = `${final_val.join('').trim()}`;
    return raw_html;
  };

  // column_names: teacher_id, session_type, group_session_name, session_status,member_type, session_duration, measure_names, measure_values, teacher_rate, currency
  parse_results_new = (data) =>
    this.setState({ loading: true }, () => {
      const all_teacher_ids = data.map((t) => t.teacher_id);
      const teacher_ids = [...new Set(all_teacher_ids)];
      const final_data = [];
      for (const teacher_id of teacher_ids) {
        if (!teacher_id) {
          continue;
        }
        const teacher_data = data.filter((t) => t.teacher_id === teacher_id);
        const final_teacher_data = {
          row_val: Math.ceil(teacher_data.length / 2),
          teacher_id: teacher_id.replace(',', ''),
          session_type: [],
          name: teacher_data[0].teacher_name
        };
        const session_types = [...new Set(teacher_data.map((t) => t.session_type))];
        let row_val_new = 0;
        for (const session_type of session_types) {
          const session_type_data = teacher_data.filter((t) => t.session_type === session_type);
          const session_type_obj = {
            session_type,
            row_val: Math.ceil(session_type_data.length / 2),
            group_session_name: []
          };
          let session_type_row_val = 0;
          const group_class_names = [
            ...new Set(session_type_data.map((t) => t.group_session_name))
          ];
          for (const group_class_name of group_class_names) {
            let group_class_data;
            if (group_class_name) {
              group_class_data = session_type_data.filter(
                (t) => t.group_session_name === group_class_name
              );
            } else {
              group_class_data = session_type_data.filter((t) => !t.group_session_name);
            }
            const class_obj = {
              group_session_name: group_class_name,
              session_status: []
            };
            const session_statuses = [...new Set(group_class_data.map((t) => t.session_status))];
            let group_class_row_val = 0;
            for (const session_status of session_statuses) {
              const session_status_data = group_class_data.filter(
                (t) => t.session_status === session_status
              );
              const session_data = {
                session_status,
                member_type: []
              };
              const member_types = [...new Set(session_status_data.map((s) => s.member_type))];
              const member_final = [];
              let row_val_new = 0;
              for (const member_type of member_types) {
                let member_type_data;
                if (member_type) {
                  member_type_data = session_status_data.filter(
                    (t) => t.member_type === member_type
                  );
                } else {
                  member_type_data = session_status_data.filter((t) => !t.member_type);
                }
                const member_obj = { member_type, row_val: Math.ceil(member_type_data.length / 2) };
                const durations = [...new Set(member_type_data.map((s) => s.session_duration))];
                const duration_final = {
                  row_val: durations.length,
                  duration: []
                };
                const member_row_val_new = durations.length;
                row_val_new += durations.length;
                for (const duration of durations) {
                  const duration_obj = {
                    duration
                  };
                  const duration_data = member_type_data.filter(
                    (s) => s.session_duration === duration
                  );
                  const first_duration = duration_data[0];
                  duration_obj.currency = first_duration.currency;
                  duration_obj.teacher_rate = first_duration.teacher_rate;
                  const measure_names = duration_data.map((s) => s.measure_names);
                  const measure_first_string = '';
                  const measure_string = '';
                  for (let a = 0; a < measure_names.length; a++) {
                    const measure = measure_names[a];
                    const measure_val = duration_data.find((s) => s.measure_names === measure);
                    duration_obj[measure] = measure_val.measure_values;
                  }
                  duration_final.duration.push(duration_obj);
                }
                member_obj.duration = duration_final;
                member_obj.row_val_new = member_row_val_new;
                session_data.member_type.push(member_obj);
              }
              session_data.row_val_new = row_val_new;
              group_class_row_val += row_val_new;
              session_data.row_val = Math.ceil(session_status_data.length / 2);
              class_obj.session_status.push(session_data);
            }
            class_obj.row_val = Math.ceil(group_class_data.length / 2);
            class_obj.row_val_new = group_class_row_val;
            session_type_row_val += group_class_row_val;
            session_type_obj.group_session_name.push(class_obj);
          }
          session_type_obj.row_val_new = session_type_row_val;
          row_val_new += session_type_row_val;
          final_teacher_data.session_type.push(session_type_obj);
          // break;
        }
        final_teacher_data.row_val_new = row_val_new;
        const teacher_amounts = teacher_data.filter((t) => t.measure_names === 'Amount');

        const teacher_sessions = teacher_data.filter((t) => t.measure_names === 'Total Sessions');
        const total_amount = teacher_amounts.reduce(
          (total, t) => total + parseInt(t.measure_values.replace(',', '')),
          0
        );

        const total_sessions = teacher_sessions.reduce(
          (total, t) => total + parseInt(t.measure_values),
          0
        );
        final_teacher_data.total_amount = total_amount;
        final_teacher_data.total_sessions = total_sessions;
        final_data.push(final_teacher_data);
      }
      // this.setState({ final_data, loading: false })
      const all_teacher_data = [...this.state.all_teacher_data];
      for (const new_teacher_data of final_data) {
        const index = all_teacher_data.findIndex((a) => a.id === new_teacher_data.teacher_id);
        if (index !== -1) {
          const teacher_data = all_teacher_data[index];
          teacher_data.payment_content = this.parse_teacher_data(new_teacher_data);
        } else {
          const teacher_obj = {
            id: new_teacher_data.teacher_id,
            teacher_name: new_teacher_data.name,
            payment_content: this.parse_teacher_data(new_teacher_data)
          };
          all_teacher_data.push(teacher_obj);
        }
      }
      this.setState({ all_teacher_data: [...all_teacher_data], loading: false });
    });

  parse_teacher_data = (teacher_data) => {
    const final_val = [];
    for (let i = 0; i < teacher_data.row_val_new; i++) {
      final_val.push(`<tr>[col1]</tr>`);
    }
    let col1Index = 0;
    let col2Index = 0;
    let col3Index = 0;
    let col4Index = 0;
    let col5Index = 0;
    for (const session_type of teacher_data.session_type) {
      for (let i = 0; i < session_type.row_val_new; i++) {
        let row = final_val[col1Index];
        if (i == 0) {
          row = row.replace(
            '[col1]',
            `<td rowspan=${session_type.row_val_new}>${session_type.session_type}</td>[col2]`
          );
        } else row = row.replace('[col1]', `[col2]`);
        final_val[col1Index] = row;
        col1Index++;
      }
      for (const group_session_name of session_type.group_session_name) {
        for (let i = 0; i < group_session_name.row_val_new; i++) {
          let row = final_val[col2Index];
          if (i == 0) {
            row = row.replace(
              '[col2]',
              `<td rowspan=${group_session_name.row_val_new}>${group_session_name.group_session_name}</td>[col3]`
            );
          } else row = row.replace('[col2]', `[col3]`);
          final_val[col2Index] = row;
          col2Index++;
        }
        for (const session_status of group_session_name.session_status) {
          for (let i = 0; i < session_status.row_val_new; i++) {
            let row = final_val[col3Index];
            if (i == 0) {
              row = row.replace(
                '[col3]',
                `<td rowspan=${session_status.row_val_new}>${session_status.session_status}</td>[col4]`
              );
            } else row = row.replace('[col3]', `[col4]`);
            final_val[col3Index] = row;
            col3Index++;
          }
          for (const member_type of session_status.member_type) {
            for (let i = 0; i < member_type.row_val_new; i++) {
              let row = final_val[col4Index];
              if (i == 0) {
                row = row.replace(
                  '[col4]',
                  `<td rowspan=${member_type.row_val_new}>${member_type.member_type}</td>[col5]`
                );
              } else row = row.replace('[col4]', `[col5]`);
              final_val[col4Index] = row;
              col4Index++;
            }
            for (const duration of member_type.duration.duration) {
              const total_sessions = duration['Total Sessions'] ? duration['Total Sessions'] : '';
              const total_amount = duration.Amount ? duration.Amount : '';
              const total_joinees = duration['Total Joinees'] ? duration['Total Joinees'] : '';
              const currency = duration.currency ? duration.currency : '';
              const teacher_rate = duration.teacher_rate ? duration.teacher_rate : '';
              const col_6_value = `<td>${duration.duration}</td><td>${total_sessions}</td><td>${total_joinees}</td><td>${currency}</td><td>${teacher_rate}</td><td>${total_amount}</td>`;
              let row = final_val[col5Index];
              row = row.replace('[col5]', col_6_value);
              final_val[col5Index] = row;
              col5Index++;
            }
          }
        }
      }
    }
    final_val.push(
      `<tr><td colspan="5">Total</td><td>${teacher_data?.total_sessions}</td><td></td><td></td><td></td><td>${teacher_data?.total_amount}</td></tr>`
    );
    const raw_html = `${final_val.join('').trim()}`;
    return raw_html;
    // this.setState({final_data: final_val})
  };

  parse_early_exit_penalty = (data) => {
    const all_teacher_ids = data.map((t) => t.teacher_id);
    const teacher_ids = [...new Set(all_teacher_ids)];
    const final_data = [];
    for (const teacher_id of teacher_ids) {
      if (!teacher_id) {
        continue;
      }
      const teacher_data = data.filter((t) => t.teacher_id === teacher_id);
      const final_teacher_data = {
        teacher_id: teacher_id.replace(',', ''),
        session_type: [],
        name: teacher_data[0].teacher_name
      };

      let row_val = 0;
      const session_types = [...new Set(teacher_data.map((t) => t.session_type))];
      for (const session_type of session_types) {
        const session_type_data = teacher_data.filter((t) => t.session_type === session_type);
        const session_type_obj = {
          session_type,
          session_type_data: []
        };
        let row_val_session = 0;
        for (const single_session of session_type_data) {
          session_type_obj.session_type_data.push(single_session);
          row_val += 1;
          row_val_session += 1;
        }
        session_type_obj.row_val = row_val_session;
        final_teacher_data.session_type.push(session_type_obj);
      }
      final_teacher_data.row_val = row_val;
      const total_penalty = teacher_data.reduce((total, t) => {
        const penalty_val = t.penalty ? parseInt(t.penalty.replace(',', '')) : 0;
        return total + penalty_val;
      }, 0);
      final_teacher_data.total_penalty = total_penalty;
      final_data.push(final_teacher_data);
    }

    const all_teacher_data = [...this.state.all_teacher_data];
    for (const teacher_data of final_data) {
      const index = all_teacher_data.findIndex((a) => a.id === teacher_data.teacher_id);
      if (index !== -1) {
        const teach_data = all_teacher_data[index];
        teach_data.early_exit_penalty_content = this.parse_early_exit_penalty_data(teacher_data);
      } else {
        const teacher_obj = {
          id: teacher_data.teacher_id,
          teacher_name: teacher_data.name,
          early_exit_penalty_content: this.parse_early_exit_penalty_data(teacher_data)
        };
        all_teacher_data.push(teacher_obj);
      }
    }
    this.setState({ all_teacher_data: [...all_teacher_data], loading: false });
  };

  parse_early_exit_penalty_data = (teacher_data) => {
    const final_val = [];
    for (let i = 0; i < teacher_data.row_val; i++) {
      final_val.push(`<tr>[col1]</tr>`);
    }
    let col1Index = 0;
    let col2Index = 0;
    for (const session_type of teacher_data.session_type) {
      for (let i = 0; i < session_type.row_val; i++) {
        let row = final_val[col1Index];
        if (i == 0) {
          row = row.replace(
            '[col1]',
            `<td rowspan=${session_type.row_val}>${session_type.session_type}</td>[col2]`
          );
        } else row = row.replace('[col1]', `[col2]`);
        final_val[col1Index] = row;
        col1Index++;
      }
      for (const data of session_type.session_type_data) {
        const penalty = data.penalty ? data.penalty : 0;
        const col_2_value = `
                                    <td>${data.session_duration}</td>
                                    <td>${data.teacher_rate}</td>
                                    <td>${data.class_date}</td>
                                    <td>${data.class_time}</td>
                                    <td>${data.class_name}</td>
                                    <td>${data.teacher_max_leaving_time}</td>
                                    <td>${penalty}</td>`;
        let row = final_val[col2Index];
        row = row.replace('[col2]', col_2_value);
        final_val[col2Index] = row;
        col2Index++;
      }
    }
    final_val.push(`<tr><td colspan="7">Total</td><td>${teacher_data?.total_penalty}</td></tr>`);
    const raw_html = `${final_val.join('').trim()}`;
    return raw_html;
  };

  parse_results_audio_video_turn_on = (data) =>
    this.setState({ loading: true }, () => {
      const all_teacher_ids = data.map((t) => t.teacher_id);
      const teacher_ids = [...new Set(all_teacher_ids)];
      const final_data = [];
      for (const teacher_id of teacher_ids) {
        if (!teacher_id) {
          continue;
        }
        const teacher_data = data.filter((t) => t.teacher_id === teacher_id);
        const final_teacher_data = {
          teacher_id: teacher_id.replace(',', ''),
          session_type: [],
          name: teacher_data[0].teacher_name
        };
        let row_val = 0;
        const session_types = [...new Set(teacher_data.map((t) => t.session_type))];
        for (const session_type of session_types) {
          const session_type_data = teacher_data.filter((t) => t.session_type === session_type);
          const session_type_obj = {
            session_type,
            session_type_data: []
          };
          let row_val_session = 0;
          for (const single_session of session_type_data) {
            const obj = {
              class_date: single_session.class_date,
              class_time: single_session.class_time,
              first_student_join_time: single_session.first_student_join_time,
              penalty: single_session.penalty,
              session_duration: single_session.session_duration,
              session_join_time: single_session.session_join_time,
              teacher_rate: single_session.teacher_rate,
              class_name: single_session.class_name,
              waive_penalty_reason: single_session.waive_penalty_reason
            };
            session_type_obj.session_type_data.push(obj);
            row_val += 1;
            row_val_session += 1;
          }
          session_type_obj.row_val = row_val_session;
          final_teacher_data.session_type.push(session_type_obj);
        }
        final_teacher_data.row_val = row_val;
        const total_penalty = teacher_data.reduce((total, t) => {
          const penalty_val = t.penalty ? parseInt(t.penalty) : 0;
          return total + penalty_val;
        }, 0);
        final_teacher_data.total_penalty = total_penalty;
        final_data.push(final_teacher_data);
      }
      // let all_teacher_data = [];
      const all_teacher_data = [...this.state.all_teacher_data];
      for (const teacher_data of final_data) {
        const index = all_teacher_data.findIndex((a) => a.id === teacher_data.teacher_id);
        if (index !== -1) {
          const teach_data = all_teacher_data[index];
          teach_data.late_audio_video_turn_on_content =
            this.parse_late_audio_video_turn_on_data(teacher_data);
        } else {
          const teacher_obj = {
            id: teacher_data.teacher_id,
            teacher_name: teacher_data.name,
            late_audio_video_turn_on_content: this.parse_late_audio_video_turn_on_data(teacher_data)
          };
          all_teacher_data.push(teacher_obj);
        }
      }
      this.setState({ all_teacher_data: [...all_teacher_data], loading: false });
    });

  parse_late_audio_video_turn_on_data = (teacher_data) => {
    const final_val = [];
    for (let i = 0; i < teacher_data.row_val; i++) {
      final_val.push(`<tr>[col1]</tr>`);
    }
    let col1Index = 0;
    let col2Index = 0;
    for (const session_type of teacher_data.session_type) {
      for (let i = 0; i < session_type.row_val; i++) {
        let row = final_val[col1Index];
        if (i == 0) {
          row = row.replace(
            '[col1]',
            `<td rowspan=${session_type.row_val}>${session_type.session_type}</td>[col2]`
          );
        } else row = row.replace('[col1]', `[col2]`);
        final_val[col1Index] = row;
        col1Index++;
      }
      for (const data of session_type.session_type_data) {
        const penalty = data.penalty ? data.penalty : 0;
        const col_2_value = `<td>${data.session_duration}</td>
                                    <td>${data.teacher_rate}</td>
                                    <td>${data.class_date}</td>
                                    <td>${data.class_time}</td>
                                    <td>${data.class_name}</td>
                                    <td>${data.first_student_join_time}</td>
                                    <td>${data.session_join_time}</td>
                                    <td>${data.waive_penalty_reason}</td>
                                    <td>${penalty}</td>`;
        let row = final_val[col2Index];
        row = row.replace('[col2]', col_2_value);
        final_val[col2Index] = row;
        col2Index++;
      }
    }
    final_val.push(`<tr><td colspan="9">Total</td><td>${teacher_data?.total_penalty}</td></tr>`);
    const raw_html = `${final_val.join('').trim()}`;
    return raw_html;
  };

  parse_results_cancellation_within_one_hour = (data) => {
    const all_teacher_ids = data.map((t) => t.teacher_id);
    const teacher_ids = [...new Set(all_teacher_ids)];
    const final_data = [];
    for (const teacher_id of teacher_ids) {
      if (!teacher_id) {
        continue;
      }
      const teacher_data = data.filter((t) => t.teacher_id === teacher_id);
      const final_teacher_data = {
        teacher_id: teacher_id.replace(',', ''),
        session_type: [],
        name: teacher_data[0].teacher_name
      };
      let row_val = 0;
      const session_types = [...new Set(teacher_data.map((t) => t.session_type))];
      for (const session_type of session_types) {
        const session_type_data = teacher_data.filter((t) => t.session_type === session_type);
        const session_type_obj = {
          session_type,
          session_type_data: []
        };
        let row_val_session = 0;
        for (const single_session of session_type_data) {
          session_type_obj.session_type_data.push(single_session);
          row_val += 1;
          row_val_session += 1;
        }
        session_type_obj.row_val = row_val_session;
        final_teacher_data.session_type.push(session_type_obj);
      }
      final_teacher_data.row_val = row_val;
      const total_penalty = teacher_data.reduce((total, t) => {
        const penalty_val = t.penalty ? parseInt(t.penalty.replace(',', '')) : 0;
        return total + penalty_val;
      }, 0);
      final_teacher_data.total_penalty = total_penalty;
      final_data.push(final_teacher_data);
    }
    const all_teacher_data = [...this.state.all_teacher_data];
    for (const teacher_data of final_data) {
      const index = all_teacher_data.findIndex((a) => a.id === teacher_data.teacher_id);
      if (index !== -1) {
        const teach_data = all_teacher_data[index];
        teach_data.cancellation_content_within_one_hour_content =
          this.parse_cancellation_within_one_hour_data(teacher_data);
      } else {
        const teacher_obj = {
          id: teacher_data.teacher_id,
          teacher_name: teacher_data.name,
          cancellation_content_within_one_hour_content:
            this.parse_cancellation_within_one_hour_data(teacher_data)
        };
        all_teacher_data.push(teacher_obj);
      }
    }
    this.setState({ all_teacher_data: [...all_teacher_data], loading: false });
  };

  parse_cancellation_within_one_hour_data = (teacher_data) => {
    const final_val = [];
    for (let i = 0; i < teacher_data.row_val; i++) {
      final_val.push(`<tr>[col1]</tr>`);
    }
    let col1Index = 0;
    let col2Index = 0;
    for (const session_type of teacher_data.session_type) {
      for (let i = 0; i < session_type.row_val; i++) {
        let row = final_val[col1Index];
        if (i == 0) {
          row = row.replace(
            '[col1]',
            `<td rowspan=${session_type.row_val}>${session_type.session_type}</td>[col2]`
          );
        } else row = row.replace('[col1]', `[col2]`);
        final_val[col1Index] = row;
        col1Index++;
      }
      for (const data of session_type.session_type_data) {
        const penalty = data.penalty ? data.penalty : 0;
        const col_2_value = `<td>${data.duration}</td>
                                    <td>${data.class_date}</td>
                                    <td>${data.class_time}</td>
                                    <td>${data.class_name}</td>
                                    <td>${data.teacher_rate}</td>
                                    <td>${data.cancellation_time}</td>
                                    <td>${data.session_booking_time}</td>
                                    <td>${penalty}</td>`;
        let row = final_val[col2Index];
        row = row.replace('[col2]', col_2_value);
        final_val[col2Index] = row;
        col2Index++;
      }
    }
    final_val.push(`<tr><td colspan="8">Total</td><td>${teacher_data?.total_penalty}</td></tr>`);
    const raw_html = `${final_val.join('').trim()}`;
    return raw_html;
  };

  parse_results_replacement_within_one_hour = (data) => {
    const all_teacher_ids = data.map((t) => t.teacher_id);
    const teacher_ids = [...new Set(all_teacher_ids)];
    const final_data = [];
    for (const teacher_id of teacher_ids) {
      if (!teacher_id) {
        continue;
      }
      const teacher_data = data.filter((t) => t.teacher_id === teacher_id);
      const final_teacher_data = {
        teacher_id: teacher_id.replace(',', ''),
        session_type: [],
        name: teacher_data[0].teacher_name
      };
      let row_val = 0;
      const session_types = [...new Set(teacher_data.map((t) => t.session_type))];
      for (const session_type of session_types) {
        const session_type_data = teacher_data.filter((t) => t.session_type === session_type);
        const session_type_obj = {
          session_type,
          session_type_data: []
        };
        let row_val_session = 0;
        for (const single_session of session_type_data) {
          session_type_obj.session_type_data.push(single_session);
          row_val += 1;
          row_val_session += 1;
        }
        session_type_obj.row_val = row_val_session;
        final_teacher_data.session_type.push(session_type_obj);
      }
      final_teacher_data.row_val = row_val;
      const total_penalty = teacher_data.reduce((total, t) => {
        const penalty_val = t.penalty ? parseInt(t.penalty.replace(',', '')) : 0;
        return total + penalty_val;
      }, 0);
      final_teacher_data.total_penalty = total_penalty;
      final_data.push(final_teacher_data);
    }
    const all_teacher_data = [...this.state.all_teacher_data];
    for (const teacher_data of final_data) {
      const index = all_teacher_data.findIndex((a) => a.id === teacher_data.teacher_id);
      if (index !== -1) {
        const teach_data = all_teacher_data[index];
        teach_data.replacement_content_within_one_hour_content =
          this.parse_replacement_within_one_hour_data(teacher_data);
      } else {
        const teacher_obj = {
          id: teacher_data.teacher_id,
          teacher_name: teacher_data.name,
          replacement_content_within_one_hour_content:
            this.parse_replacement_within_one_hour_data(teacher_data)
        };
        all_teacher_data.push(teacher_obj);
      }
    }
    this.setState({ all_teacher_data: [...all_teacher_data], loading: false });
  };

  parse_replacement_within_one_hour_data = (teacher_data) => {
    const final_val = [];
    for (let i = 0; i < teacher_data.row_val; i++) {
      final_val.push(`<tr>[col1]</tr>`);
    }
    let col1Index = 0;
    let col2Index = 0;
    for (const session_type of teacher_data.session_type) {
      for (let i = 0; i < session_type.row_val; i++) {
        let row = final_val[col1Index];
        if (i == 0) {
          row = row.replace(
            '[col1]',
            `<td rowspan=${session_type.row_val}>${session_type.session_type}</td>[col2]`
          );
        } else row = row.replace('[col1]', `[col2]`);
        final_val[col1Index] = row;
        col1Index++;
      }
      for (const data of session_type.session_type_data) {
        const penalty = data.penalty ? data.penalty : 0;
        const col_2_value = `<td>${data.duration}</td>
                                    <td>${data.class_date}</td>
                                    <td>${data.class_time}</td>
                                    <td>${data.class_name}</td>
                                    <td>${data.teacher_rate}</td>
                                    <td>${data.replacement_request_time}</td>
                                    <td>${data.replacement_session_status}</td>
                                    <td>${penalty}</td>`;
        let row = final_val[col2Index];
        row = row.replace('[col2]', col_2_value);
        final_val[col2Index] = row;
        col2Index++;
      }
    }
    final_val.push(`<tr><td colspan="8">Total</td><td>${teacher_data?.total_penalty}</td></tr>`);
    const raw_html = `${final_val.join('').trim()}`;
    return raw_html;
  };

  parse_results_reschedules = (data) =>
    this.setState({ loading: true }, () => {
      const all_teacher_ids = data.map((t) => t.teacher_id);
      const teacher_ids = [...new Set(all_teacher_ids)];
      const final_data = [];
      for (const teacher_id of teacher_ids) {
        if (!teacher_id) {
          continue;
        }
        const teacher_data = data.filter((t) => t.teacher_id === teacher_id);
        const final_teacher_data = {
          teacher_id: teacher_id.replace(',', ''),
          session_type: [],
          name: teacher_data[0].teacher_name
        };
        let row_val = 0;
        const session_types = [...new Set(teacher_data.map((t) => t.session_type))];
        for (const session_type of session_types) {
          const session_type_data = teacher_data.filter((t) => t.session_type === session_type);
          const session_type_obj = {
            session_type,
            session_type_data: []
          };
          let row_val_session = 0;
          for (const single_session of session_type_data) {
            session_type_obj.session_type_data.push(single_session);
            row_val += 1;
            row_val_session += 1;
          }
          session_type_obj.row_val = row_val_session;
          final_teacher_data.session_type.push(session_type_obj);
        }
        final_teacher_data.row_val = row_val;
        const total_penalty = teacher_data.reduce((total, t) => {
          const penalty_val = t.penalty ? parseInt(t.penalty.replace(',', '')) : 0;
          return total + penalty_val;
        }, 0);
        final_teacher_data.total_penalty = total_penalty;
        final_data.push(final_teacher_data);
      }
      const all_teacher_data = [...this.state.all_teacher_data];
      for (const teacher_data of final_data) {
        const index = all_teacher_data.findIndex((a) => a.id === teacher_data.teacher_id);
        if (index !== -1) {
          const teach_data = all_teacher_data[index];
          teach_data.reschedules_content = this.parse_reschedules_data(teacher_data);
        } else {
          const teacher_obj = {
            id: teacher_data.teacher_id,
            teacher_name: teacher_data.name,
            reschedules_content: this.parse_reschedules_data(teacher_data)
          };
          all_teacher_data.push(teacher_obj);
        }
      }
      this.setState({ all_teacher_data: [...all_teacher_data], loading: false });
    });

  parse_reschedules_data = (teacher_data) => {
    const final_val = [];
    for (let i = 0; i < teacher_data.row_val; i++) {
      final_val.push(`<tr>[col1]</tr>`);
    }
    let col1Index = 0;
    let col2Index = 0;
    for (const session_type of teacher_data.session_type) {
      for (let i = 0; i < session_type.row_val; i++) {
        let row = final_val[col1Index];
        if (i == 0) {
          row = row.replace(
            '[col1]',
            `<td rowspan=${session_type.row_val}>${session_type.session_type}</td>[col2]`
          );
        } else row = row.replace('[col1]', `[col2]`);
        final_val[col1Index] = row;
        col1Index++;
      }
      for (const data of session_type.session_type_data) {
        const penalty = data.penalty ? data.penalty : 0;
        const col_2_value = `<td>${data.initiated_by}</td>
                                    <td>${data.session_status}</td>
                                    <td>${data.reschedule_time}</td>
                                    <td>${data.session_start_time}</td>
                                    <td>${data.reschedule_within}</td>
                                    <td>${data.student_name}</td>
                                    <td>${data.original_session_time}</td>
                                    <td>${data.new_session_time}</td>
                                    <td>${data.teacher_rate}</td>
                                    <td>${penalty}</td>`;
        let row = final_val[col2Index];
        row = row.replace('[col2]', col_2_value);
        final_val[col2Index] = row;
        col2Index++;
      }
    }
    final_val.push(`<tr><td colspan="10">Total</td><td>${teacher_data?.total_penalty}</td></tr>`);
    const raw_html = `${final_val.join('').trim()}`;
    return raw_html;
  };

  select_file = (e, title) => {
    this.setState({ loading: true });
    const { files } = e.target;
    if (files && files.length > 0) {
      Papa.parse(files[0], {
        header: true,
        complete: (results) => {
          // this.parse_results_new(results.data)
          if (title === 'late_join') {
            this.parse_results_late_join(results.data);
          } else if (title === 'no_show') {
            this.parse_results_no_show(results.data);
          } else if (title === 'cancellation') {
            this.parse_results_cancellation(results.data);
          } else if (title === 'replacement') {
            this.parse_results_replacement(results.data);
          } else if (title === 'day_wise') {
            this.parse_results_day_wise(results.data);
          } else if (title === 'conversion_bonus') {
            this.parse_results_conversion(results.data);
          } else if (title === 'early_exit_penalty') {
            this.parse_early_exit_penalty(results.data);
          } else if (title === 'late_audio_video_turn_on') {
            this.parse_results_audio_video_turn_on(results.data);
          } else if (title === 'cancellation_within_1_hour') {
            this.parse_results_cancellation_within_one_hour(results.data);
          } else if (title === 'replacement_within_1_hour') {
            this.parse_results_replacement_within_one_hour(results.data);
          } else if (title === 'reschedules') {
            this.parse_results_reschedules(results.data);
          } else this.parse_results_new(results.data);
        }
      });
    } else {
      this.setState({ loading: false });
    }
  };

  upload_file = (e) => {
    const { files } = e.target;
    if (files) {
      const url = get_api_url('upload_teacher_payments');
      const data = new FormData();
      data.append('teacher_payment', files[0]);
      post_api(url, data, true, 'formdata')
        .then((res) => {})
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    }
  };

  render_file_upload = (title) => {
    const final_title = titles[title] ? titles[title] : 'Monthly payment';
    return (
      <div>
        <p>Enter {final_title} file</p>
        <input type="file" accept=".csv,.xlsx,.xls" onChange={(e) => this.select_file(e, title)} />
      </div>
    );
  };

  render_subject = () => {
    const { subject, email_body } = this.state;
    return (
      <div>
        <div style={{ marginTop: '20px' }}>
          <CustomInput
            label="Enter subject"
            onChange={this.onCommonChange}
            name="subject"
            value={subject}
            style={{ width: '100%' }}
          />
        </div>
        <div>
          <h4>Enter body:</h4>
          <textarea
            style={{ width: '100%', height: '100px' }}
            value={email_body}
            name="email_body"
            onChange={this.onCommonChange}
            maxLength="2000"
          />
        </div>
      </div>
    );
  };

  delete_teacher_data = (id) => {
    const teacher_data = [...this.state.all_teacher_data];
    const teacher_index = teacher_data.findIndex((t) => t.id === id);
    if (teacher_index !== -1) {
      const final_all_items = [
        ...teacher_data.slice(0, teacher_index),
        ...teacher_data.slice(teacher_index + 1)
      ];
      this.setState({ all_teacher_data: final_all_items });
    }
  };

  get_teacher_content = (teacher_data) => {
    const { email_body } = this.state;
    let email_template = payment_body.toString().replace('[USER-NAME]', teacher_data.teacher_name);

    const payment_content = teacher_data.payment_content
      ? montly_payment_block.toString().replace('[TEACHER-BLOCK]', teacher_data.payment_content)
      : '';
    email_template = email_template.replace('[TEACHER-CONTENT]', payment_content);
    email_template = email_template.replace('[EMAIL-BODY]', email_body);

    const conversion_content = teacher_data.conversion_content
      ? conversion_block.toString().replace('[CONVERSION-BLOCK]', teacher_data.conversion_content)
      : '';
    email_template = email_template.replace('[CONVERSION-CONTENT]', conversion_content);

    const no_show_content = teacher_data.no_show_content
      ? no_show_block.toString().replace('[NO-SHOW-BLOCK]', teacher_data.no_show_content)
      : '';
    email_template = email_template.replace('[NO-SHOW-CONTENT]', no_show_content);

    const late_join_content = teacher_data.late_join_content
      ? late_join_block.toString().replace('[LATE-JOIN-BLOCK]', teacher_data.late_join_content)
      : '';
    email_template = email_template.replace('[LATE-JOIN-CONTENT]', late_join_content);

    const cancellation_content = teacher_data.cancellation_content
      ? cancellation_block
          .toString()
          .replace('[CANCELLATION-BLOCK]', teacher_data.cancellation_content)
      : '';
    email_template = email_template.replace('[CANCELLATION-CONTENT]', cancellation_content);

    const replacement_content = teacher_data.replacement_content
      ? replacement_block
          .toString()
          .replace('[REPLACEMENT-BLOCK]', teacher_data.replacement_content)
      : '';
    email_template = email_template.replace('[REPLACEMENT-CONTENT]', replacement_content);

    const day_wise_content = teacher_data.day_wise_content ? teacher_data.day_wise_content : '';
    email_template = email_template.replace('[DAY-WISE-CONTENT]', day_wise_content);

    const early_exit_penalty_content = teacher_data.early_exit_penalty_content
      ? early_exit_penalty_block
          .toString()
          .replace('[EARLY-EXIT-PENALTY-BLOCK]', teacher_data.early_exit_penalty_content)
      : '';
    email_template = email_template.replace(
      '[EARLY-EXIT-PENALTY-CONTENT]',
      early_exit_penalty_content
    );

    const late_audio_video_turn_on_content = teacher_data.late_audio_video_turn_on_content
      ? late_audio_video_turn_on
          .toString()
          .replace(
            '[LATE-AUDIO-VIDEO-TURN-ON-BLOCK]',
            teacher_data.late_audio_video_turn_on_content
          )
      : '';
    email_template = email_template.replace(
      '[LATE-AUDIO-VIDEO-TURN-ON-CONTENT]',
      late_audio_video_turn_on_content
    );

    const cancellation_content_within_one_hour_content =
      teacher_data.cancellation_content_within_one_hour_content
        ? cancellation_block_within_one_hour
            .toString()
            .replace(
              '[CANCELLATION-BLOCK-WITHIN-ONE-HOUR-BLOCK]',
              teacher_data.cancellation_content_within_one_hour_content
            )
        : '';
    email_template = email_template.replace(
      '[CANCELLATION-BLOCK-WITHIN-ONE-HOUR-CONTENT]',
      cancellation_content_within_one_hour_content
    );

    const replacement_content_within_one_hour_content =
      teacher_data.replacement_content_within_one_hour_content
        ? replacement_block_within_one_hour
            .toString()
            .replace(
              '[REPLACEMENT-BLOCK-WITHIN-ONE-HOUR]',
              teacher_data.replacement_content_within_one_hour_content
            )
        : '';
    email_template = email_template.replace(
      '[REPLACEMENT-WITHIN-ONE-HOUR-CONTENT]',
      replacement_content_within_one_hour_content
    );

    const reschedules_content = teacher_data.reschedules_content
      ? reschedules_block
          .toString()
          .replace('[RESCHEDULES-BLOCK]', teacher_data.reschedules_content)
      : '';
    email_template = email_template.replace('[RESCHEDULES-CONTENT]', reschedules_content);
    return email_template;
  };

  render_single_teacher = (teacher_data) => (
    <div key={teacher_data.id}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
        <h2>For {teacher_data.teacher_name}</h2>
        <button onClick={() => this.delete_teacher_data(teacher_data.id)}>Delete</button>
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: email_template }}></div> */}
      <iframe srcDoc={this.get_teacher_content(teacher_data)} width="100%" height="1400" />
    </div>
  );

  submit_data = (teacher_data) => {
    const { subject, email_body } = this.state;
    const url = get_api_url('send_teacher_payments_email');
    const final_teacher_data = [];
    for (const single_teacher_data of teacher_data) {
      const new_teacher_data = {
        id: single_teacher_data.id,
        teacher_name: single_teacher_data.teacher_name,
        content: this.get_teacher_content(single_teacher_data)
      };
      final_teacher_data.push(new_teacher_data);
    }
    const payload = { teacher_data: final_teacher_data, subject, email_body };
    return post_api(url, payload, true);
  };

  submit_emails = () =>
    this.setState({ loading: true }, () => {
      const { all_teacher_data } = this.state;
      const promises = [];
      for (let i = 0; i < all_teacher_data.length; i++) {
        const slice_data = all_teacher_data.slice(5 * i, 5 * (i + 1));
        promises.push(this.submit_data(slice_data));
      }
      Promise.all(promises)
        .then(() => {
          this.props.set_notification_variable(true, 'success', 'Emails sent');
          this.setState({
            loading: false,
            teacher_data: [],
            final_data: [],
            all_teacher_data: [],
            subject: '',
            email_body: '',
            open_confirm: false
          });
        })
        .catch((e) => {
          let err_message;
          try {
            err_message = e.response.data.message;
          } catch (err) {
            err_message = 'Some error occured. Please contact dev team.';
          }
          this.props.set_notification_variable(true, 'error', err_message);
          this.setState({ loading: false });
        });
    });

  onCommonChange = (e) => {
    const key = e.target.name;
    const { value } = e.target;
    this.setState({ [key]: value });
  };

  render_data = () => {
    const { all_teacher_data } = this.state;
    // let teacher_data = final_data[0];
    if (all_teacher_data.length > 0) {
      // let final_teachers = [all_teacher_data[0]]
      return all_teacher_data.map(this.render_single_teacher);
    }
    // return this.render_single_teacher(final_teachers)
  };

  toggle_open_confirm = () => this.setState({ open_confirm: !this.state.open_confirm });

  render_confirm = () => (
    <div>
      <h4>Are you sure you want to send emails</h4>
      <button onClick={this.submit_emails}>Confirm</button>
      <button onClick={this.toggle_open_confirm}>Cancel</button>
    </div>
  );

  render() {
    const { loading, open_confirm, email_body, subject, all_teacher_data } = this.state;
    return (
      <CommonHeader loading={loading} title="Teacher Payments">
        <CustomModal show={open_confirm} close={this.toggle_open_confirm}>
          {this.render_confirm()}
        </CustomModal>
        {this.render_file_upload('payment')}
        {this.render_file_upload('conversion_bonus')}
        {this.render_file_upload('no_show')}
        {this.render_file_upload('late_join')}
        {this.render_file_upload('cancellation')}
        {this.render_file_upload('replacement')}
        {this.render_file_upload('day_wise')}
        {this.render_file_upload('early_exit_penalty')}
        {this.render_file_upload('late_audio_video_turn_on')}
        {this.render_file_upload('cancellation_within_1_hour')}
        {this.render_file_upload('replacement_within_1_hour')}
        {this.render_file_upload('reschedules')}
        {this.render_subject()}
        {this.render_data()}
        <Button
          onClick={this.toggle_open_confirm}
          disabled={!subject || !email_body || all_teacher_data.length === 0}
        >
          Submit
        </Button>
      </CommonHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  iana_timezone: state.home.iana_timezone
});

const mapDispatchToProps = (dispatch) => ({
  set_notification_variable: (show, type, mes) => {
    dispatch(actions.set_notification_variable(show, type, mes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherPayments);
