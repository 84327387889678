import React from 'react';

function TimezoneDropdown({ timezones, onChange, iana_timezone }) {
  return (
    <div className="w-[150px] max-w-sm mx-auto">
      <select
        id="timezone"
        name="timezone"
        className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        onChange={onChange}
        value={iana_timezone}
      >
        {timezones.map((group) => (
          <optgroup key={group.label} label={group.label}>
            {group.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </optgroup>
        ))}
      </select>
    </div>
  );
}

export default TimezoneDropdown;
