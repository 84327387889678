import { CallbackHandler } from "../callbacks/handler";
import ConnectionState from "../enums/ConnectionState";
import QueuedPacket from "../packets/QueuedPacket";
import { getAuthToken, getAuthUserInfo } from "../../network/cookie";
import urls from "../../network/urls";
import { logger } from "../../utils/Logger";
import { InvalidDisconnections } from "../enums/DisconnectReason";
import RawPacket from "../packets/RawPacket";
import Packet from "../enums/Packet";
import { v4 as uuidV4 } from 'uuid';

import AccountHandler from "./AccountHandler";
import PushHandler from "./PushHandler";
import PresenceHandler from "./PresenceHandler";
import MessageHandler from "./MessageHandler";
import PinHandler from "./PinHandler";
import S3Handler from "./S3Handler";
import SMSHandler from "./SMSHandler";
import RecoHandler from "./RecoHandler";
import RelayHandler from "./RelayHandler";
import CareHandler from "./CareHandler";
import GroupHandler from "./GroupHandler";
import InitHandler from "./InitHandler";
import PingHandler from "./PingHandler";
import Channel from "../models/Channel";
import ChannelType from "../enums/ChannelType";
import Config from "../models/Config";
import Message from "../models/Message";
import ChannelUtils from "../utils/ChannelUtils";
import ChannelDBOps from "../database/ChannelDBOps";
import SuspectHandler from "./SuspectHandler";
import Constants from "../config/Constants";
import GroupType from "../enums/GroupType";
import MessageDBOps from "../database/MessageDBOps";

import { fullBrowserVersion, browserName } from "react-device-detect";

type ChatCache = {
    queuedPkts: Map<string, QueuedPacket>,
    channelLoaded: Map<string, boolean>
}

type ChatClientState = {
    userId: string,
    askedPushPermission: boolean,
    connectionState: ConnectionState,
    cache: ChatCache,
    serviceWorkerRegistered: boolean,
    reconnectTimer?: number,
    ws?: WebSocket
}

export default class ChatClient {

    public static shared = new ChatClient()

    private constructor() { }

    private newCache = (): ChatCache => {
        return {
            queuedPkts: new Map<string, QueuedPacket>(),
            channelLoaded: new Map<string, boolean>()
        };
    };

    removeChannelLoadedCache = (uuid: string): void => {
        this.state.cache.channelLoaded.delete(uuid)
        MessageHandler.shared.removeChannel(uuid);
        PinHandler.shared.removeChannel(uuid);
    }

    private config = {
        reconnectInterval: 3, //seconds
        forceDisconnect: false,
    };

    private state: ChatClientState = {
        userId: "",
        askedPushPermission: false,
        connectionState: ConnectionState.NOT_CONNECTED,
        cache: this.newCache(),
        serviceWorkerRegistered: false
    };

    // ---------------------------------------------------------------
    // Connection start
    // ---------------------------------------------------------------
    initialize = async () => {

        const result = await new Promise((resolve, reject) => {
            Config.shared.setOnDBInitCallback(resolve)
        })

        logger.leaveBreadcrumb(`config.init->${result}`)

        const authInfo = getAuthUserInfo()

        if (this.state.userId !== authInfo.uuid && this.state.connectionState != ConnectionState.NOT_CONNECTED) {
            logger.error("attempt change set-token while user is connected")
            throw "already logged in with a different user, please sign out first"
        }

        return this.internalConnect();
    };

    private internalConnect = () => {
        this.config.forceDisconnect = false;
        clearTimeout(this.state.reconnectTimer);

        const oldWS = this.state.ws;
        if (oldWS && (oldWS.readyState == WebSocket.CONNECTING || oldWS.readyState == WebSocket.OPEN)) {
            return true;
        }

        this.state.connectionState = ConnectionState.NOT_CONNECTED;

        CallbackHandler.shared.callOnConnectionStateChanged(this.state.connectionState)

        const ws = new WebSocket(
            urls.wschat + `?utm=browser:${browserName}:${fullBrowserVersion}:${window.location.pathname}&pf=${Packet.Values.FALSE}`,
            ["chat", getAuthToken()]);

        ws.onopen = this.onConnectionOpened;
        ws.onmessage = this.onWSMsgReceived;
        ws.onerror = this.onConnectionError;
        ws.onclose = this.onConnectionClosed;

        this.state.ws = ws;
        this.state.connectionState = ConnectionState.CONNECTING;
        CallbackHandler.shared.callOnConnectionStateChanged(this.state.connectionState)

        return true;
    };

    connectionState = () => {
        return this.state.connectionState
    }

    onConnectionOpened = (ev: Event) => {
        logger.leaveBreadcrumb('Client: onConnectionOpened: ', {
            event: JSON.stringify(ev)
        });
        this.state.connectionState = ConnectionState.CONNECTED;
        CallbackHandler.shared.callOnConnectionStateChanged(this.state.connectionState)
    }

    onConnectionError = (ev: Event) => {
        logger.leaveBreadcrumb('Client: onConnectionError: ', {
            event: JSON.stringify(ev)
        });
        this.closeConnection();
        this.onConnectionClosed();
    };

    closeConnection = () => {
        const ws = this.state.ws;

        if (ws && (ws.readyState == WebSocket.CONNECTING || ws.readyState == WebSocket.OPEN)) {
            logger.leaveBreadcrumb("closing connection");
            ws.close();
        }
    };

    onConnectionClosed = (reason?: CloseEvent) => {
        logger.leaveBreadcrumb("onConnectionClosed: ", {
            reason: reason
        });

        this.state.connectionState = ConnectionState.NOT_CONNECTED;

        clearTimeout(this.state.reconnectTimer);

        PingHandler.shared.clear()
        PresenceHandler.shared.clear()
        MessageHandler.shared.clear()
        RecoHandler.shared.clear()
        RelayHandler.shared.clear()
        S3Handler.shared.clear()
        PinHandler.shared.clear()

        this.state.cache = this.newCache();

        CallbackHandler.shared.callOnConnectionStateChanged(this.state.connectionState)

        const tryReconnect = !this.config.forceDisconnect
            && !InvalidDisconnections.includes(reason?.code || 0);

        if (tryReconnect) {
            this.state.reconnectTimer = window.setTimeout(this.internalConnect, this.config.reconnectInterval * 1000);
        }
    };

    onWSMsgReceived = async (msg: MessageEvent) => {
        if (typeof msg.data != "string") {
            logger.debug("ChatClient: onWSMsgReceived: " + (typeof msg.data));
            return;
        }

        PingHandler.shared.restartPingTimer()

        const pkt: RawPacket = JSON.parse(msg.data);

        const pktId = pkt[Packet.Keys.ID]

        const queued = this.state.cache.queuedPkts.get(pktId);
        this.state.cache.queuedPkts.delete(pktId);

        logger.leaveBreadcrumb("ChatClient: onWSMsgReceived:", {
            response: msg.data,
            queued: queued?.packet || 'none'
        })

        try {
            await navigator.locks.request(Constants.DB_SOCKET_LOCK, async (lock) => {
                await this.processIncoming1(pkt, queued)
            });
        } catch (e) {
            logger.error(e, {
                data: 'onWSMsgReceived->try request'
            })
        }
    }

    private processIncoming1 = async (pkt: RawPacket, queued?: QueuedPacket) => {
        try {
            await this.processIncoming2(pkt, queued)

            if (pkt[Packet.Keys.ACK] == Packet.Values.TRUE) {
                this.sendAck(pkt);
            }
        } catch (e) {
            logger.error(e, {
                data: 'navigator.locks.request'
            })
        }
    }

    private processIncoming2 = async (pkt: RawPacket, queued?: QueuedPacket) => {
        switch (pkt[Packet.Keys.TYPE]) {
            case Packet.Types.CONFIG:
                {
                    this.state.userId = Config.shared.myUUID()
                    /**
                     * User as a promise here to allow processing of further packets
                     **/

                    logger.setUser(this.state.userId)
                    logger.leaveBreadcrumb("Connected", {
                        user: this.state.userId
                    }, 'state')

                    InitHandler.shared.onInitPacketReceived(pkt, queued).then(async () => {
                        this.onAuthenticated();
                        await ChannelDBOps.shared.triggerUnreadUpdate()
                    })
                }
                break;
            case Packet.Types.DISCONNECT:
                {
                    const code = pkt[Packet.Keys.RESPONSE_CODE]

                    this.config.forceDisconnect = InvalidDisconnections.includes(code);
                    logger.leaveBreadcrumb("Disconnected", {
                        code: code
                    }, 'state')

                    InitHandler.shared.onDeInitPacketReceived(pkt)
                }
                break;
            case Packet.Types.PING:
            case Packet.Types.PONG:
                {
                    await PingHandler.shared.onPacketReceived(pkt, queued)
                }
                break;
            case Packet.Types.ACCOUNT:
                {
                    await AccountHandler.shared.onPacketReceived(pkt, queued);
                }
                break
            case Packet.Types.PUSH:
                {
                    await PushHandler.shared.onPacketReceived(pkt, queued);
                }
                break
            case Packet.Types.PRESENCE:
                {
                    await PresenceHandler.shared.onPacketReceived(pkt, queued);
                }
                break
            case Packet.Types.MESSAGE:
                {
                    await MessageHandler.shared.onPacketReceived(pkt, queued);

                    const subType = pkt[Packet.Keys.SUB_TYPE] || 'none'

                    const limitedEvents = [
                        Packet.Message.Types.CHAT,
                        Packet.Message.Types.SNAPSHOT,
                        Packet.Message.Types.GROUP_ACTION,
                        Packet.Message.Types.RECEIPTS,
                        Packet.Message.Types.ARCHIVE
                    ]

                    if (limitedEvents.includes(subType)) {
                        await ChannelDBOps.shared.triggerUnreadUpdate()
                    }
                }
                break
            case Packet.Types.SMS:
                {
                    await SMSHandler.shared.onPacketReceived(pkt, queued);
                }
                break;
            case Packet.Types.RELAY:
                {
                    await RelayHandler.shared.onPacketReceived(pkt, queued)
                }
                break
            case Packet.Types.RECO:
                {
                    await RecoHandler.shared.onPacketReceived(pkt, queued)
                }
                break
            case Packet.Types.GROUP:
                {
                    await GroupHandler.shared.onPacketReceived(pkt, queued)
                }
                break
            case Packet.Types.S3:
                {
                    await S3Handler.shared.onPacketReceived(pkt, queued)
                }
                break;
            case Packet.Types.PIN:
                {
                    await PinHandler.shared.onPacketReceived(pkt, queued)
                }
                break;
            case Packet.Types.CARE:
                {
                    await CareHandler.shared.onPacketReceived(pkt, queued)
                }
                break
            case Packet.Types.SUSPECT:
                {
                    await SuspectHandler.shared.onPacketReceived(pkt, queued)
                }
                break
            default:
                {
                    logger.leaveBreadcrumb("ChatClient: onWSMsgReceived: unrecognized", {
                        type: pkt
                    })
                    logger.error("ChatClient: onWSMsgReceived: unrecognized");
                }
                break;
        }
    }

    sendAck = (res: RawPacket) => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.ACK;
        pkt[Packet.Ack.ID] = res[Packet.Keys.ID];
        pkt[Packet.Keys.ID] = uuidV4();

        return this.sendPacket(pkt);
    }

    onAuthenticated = () => {
        this.state.connectionState = ConnectionState.AUTHENTICATED;
        CallbackHandler.shared.callOnConnectionStateChanged(this.state.connectionState)
    };

    sendPacket = (
        pkt: any,
        resolve?: (value?: any) => void,
        isRequestForResponse: boolean = false
    ): boolean => {

        const ws = this.state.ws;
        const encoded = JSON.stringify(pkt);

        if (!ws || ws.readyState != WebSocket.OPEN) {
            logger.error('sendPacket: failed', {
                pkt: pkt,
                state: ws?.readyState || Packet.Values.UNKNOWN,
                request: encoded
            })
            return false;
        }

        if (resolve || isRequestForResponse) {
            const wrapper = new QueuedPacket(pkt, resolve);
            this.state.cache.queuedPkts.set(pkt.id, wrapper);
        }

        ws.send(encoded);

        logger.leaveBreadcrumb("ChatClient: send: ", {
            request: encoded,
            queued: !!resolve || isRequestForResponse
        })
        return true;
    };

    loadChannelById = async (
        channelId: string,
        type: ChannelType,
    ): Promise<Channel | undefined> => {
        const myUUID = Config.shared.myUUID()

        if (type === ChannelType.P2P && !channelId.includes(myUUID)) {
            return
        }

        if (type === ChannelType.P2P) {
            const otherUserId = ChannelUtils.otherChannelUser(channelId, myUUID)
            await AccountHandler.shared.fetchUserProfile(otherUserId)
        } else if (type === ChannelType.GROUP) {
            await GroupHandler.shared.fetchGroupByIds([channelId])
        }

        return ChannelDBOps.shared.getChannel(channelId)
    }

    loadChannel = (
        channel: Channel,
        ignoreCached: boolean = false,
        fetchProfile: boolean = true) => {

        if (channel.type === ChannelType.GROUP &&
            channel.groupType === GroupType.PRIVATE &&
            !channel.isGroupMember) {
            return false
        }

        if (channel.type === ChannelType.GROUP && channel.groupType === GroupType.PRIVATE) {

            MessageDBOps.shared.markChannelRead(channel.uuid)

            MessageDBOps.shared.latestGroupUnreads(channel.uuid).then((unreads) => {

                unreads.forEach(fromId => {

                    const dummy = new Message()
                    dummy.channel = channel.uuid
                    dummy.timeHandle = Date.now() * 1000
                    dummy.type = ChannelUtils.channelTypeToMessageType(channel.type)
                    dummy.from = fromId

                    MessageHandler.shared.sendReadReceipt(dummy)
                })
            })

        } else {
            const dummy = new Message()
            dummy.channel = channel.uuid
            dummy.timeHandle = Date.now() * 1000
            dummy.type = ChannelUtils.channelTypeToMessageType(channel.type)
            dummy.from = channel.otherUserId

            MessageHandler.shared.sendReadReceipt(dummy)
        }

        if (this.state.cache.channelLoaded.has(channel.uuid) && !ignoreCached) {
            return false;
        }

        this.state.cache.channelLoaded.set(channel.uuid, true)

        if (fetchProfile) {
            if (channel.type === ChannelType.P2P) {
                AccountHandler.shared.fetchUserProfile(channel.otherUserId)
            } else if (channel.type === ChannelType.GROUP) {

                const fetchRecent = channel.groupType === GroupType.PUBLIC &&
                    Constants.DISPLAY_RECENT_MEMBER > channel.memberCount

                GroupHandler.shared.fetchGroupByIds([channel.uuid], 0, fetchRecent)
            }
        }

        return MessageHandler.shared.fetchLatestReceipt(channel)
    }

}
